import { FC } from 'react'

import { OpenSearchForm } from '~/features/common'

type NoResultNoticeProps = {
  onClick: () => void
}

export const NoResultNotice: FC<NoResultNoticeProps> = ({ onClick }) => {
  return (
    <div className="flex flex-col items-center gap-4 bg-yellowBg px-4 py-6">
      <div className="text-center text-body-bold">
        <p>
          条件に当てはまるオフィスが
          <span className="inline-flex whitespace-nowrap">
            ありませんでした。
          </span>
        </p>
        <p className="mt-2 text-body-s font-normal">
          検索条件を変更して再度検索してください。
        </p>
      </div>
      <div className="w-[306px] md:hidden">
        <OpenSearchForm onClick={onClick} size="medium">
          条件を変更する
        </OpenSearchForm>
      </div>
    </div>
  )
}
