import { AnchorHTMLAttributes, ComponentProps, FC } from 'react'

import { cva } from 'class-variance-authority'
import { twJoin, twMerge } from 'tailwind-merge'

import { IconSmallLogo } from '~/components/ui/icons/small-logo'

type LinkToMapVariant = 'default' | 'mini' | 'over' | 'button' | 'resultButton'

const variantSetting: Record<
  LinkToMapVariant,
  { text: string; iconProps: ComponentProps<typeof IconSmallLogo> }
> = {
  default: {
    text: '今すぐマップで探す',
    iconProps: { width: 32, height: 40 },
  },
  mini: {
    text: '今すぐマップで探す',
    iconProps: { width: 21, height: 26 },
  },
  over: {
    text: '地図で表示する',
    iconProps: { fill: 'white', width: 21, height: 26 },
  },
  button: {
    text: '地図を表示',
    iconProps: { width: 21, height: 26 },
  },
  resultButton: {
    text: 'マップを表示',
    iconProps: { width: 21, height: 26 },
  },
}

const boxVariants = cva(null, {
  variants: {
    variant: {
      default: 'flex justify-center items-center h-[400px]',
      mini: 'bg-white border border-grayBtn rounded h-[52px] flex items-center justify-center hover:opacity-70',
      over: 'w-fit',
      button: 'flex justify-center items-center h-[400px]',
      resultButton: 'w-fit',
    },
  },
})

const contentVariants = cva(null, {
  variants: {
    variant: {
      default:
        'text-[24px] leading-[140%] font-bold text-brand bg-white rounded-[4px] w-[300px] h-[64px] py-3 px-[22px] w-fit cursor-pointer hover:opacity-70 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]',
      mini: 'text-body-bold text-brand',
      over: 'text-body-bold text-white bg-[rgba(0,0,0,0.7)] rounded-[55px] h-16 w-[200px]',
      button:
        'text-lg leading-[140%] font-bold text-brand bg-white rounded-[81px] py-3 px-[22px] w-fit cursor-pointer hover:opacity-70',
      resultButton: 'font-bold text-brand border rounded-[4px] h-12 w-[200px]',
    },
  },
})

type Props = {
  variant?: LinkToMapVariant
  iconIdSuffix?: string
  href?: string
  textOverride?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const LinkToMap: FC<Props> = ({
  variant = 'default',
  iconIdSuffix = 'default',
  className,
  href = '/map',
  textOverride,
  ...props
}) => {
  const { text, iconProps } = variantSetting[variant]

  return (
    <div className={twMerge('block', boxVariants({ variant }), className)}>
      <a
        className={twJoin(
          'flex items-center justify-center gap-x-2',
          contentVariants({ variant }),
        )}
        href={href}
        {...props}
      >
        <IconSmallLogo {...iconProps} idSuffix={iconIdSuffix} />
        <span>{textOverride ?? text}</span>
      </a>
    </div>
  )
}
